<template>
  <div v-show="isAllowed">
    {{ $t('booking.choosePay.q') }}
    <div class="d-flex mt-2 pay--btn-section">
      <router-link
        :to="`/transaction/pay/${orderNumber}?uuid=${applicationUuid}&type=${actionType}`"
        v-show="isAllowed"
        class="btn btn-primary mr-4 mb-2"
        >{{ $t('general.yes') }}, {{ buttonName }}
      </router-link>

      <button class="btn btn-primary mr-4 mb-2" @click="openModal">
        {{ $t('general.no') }}, {{ buttonNameOffline }}
      </button>
    </div>
  </div>
</template>

<script>
import ButtonMixin from '../mixins/buttonMixin';
export default {
  props: {
    allowedActions: {
      required: true,
      validator: (value) => {
        if (value !== null) {
          return value.constructor.name == 'Array';
        } else {
          return true;
        }
      },
    },
    type: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    applicationUuid: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: true,
    },
    orderNumber: {
      validator: (value) => {
        return typeof value === 'string' || value === null;
      },
      required: false,
    },
  },
  mixins: [ButtonMixin],
  data() {
    return {
      actionName: 'PAY',
      actionType: 'RENT_TRANSACTION',
    };
  },
  methods: {
    async openModal() {
      this.$modal.show('rent-offline-pay-modal', {
        uuid: this.applicationUuid,
        buttonName: this.buttonName,
      });
    },
  },
  computed: {
    buttonNameOffline() {
      const buttonName = this.allowedActions.find((data) => {
        return data.name === 'OFFLINE_PAY';
      });
      if (buttonName) {
        return buttonName.button_name;
      } else {
        return '';
      }
    },
    isAllowed() {
      if (!this.applicationUuid) {
        return false;
      }
      const applicationType = this.type.toUpperCase(),
        actionType = this.actionType.toUpperCase(),
        actionName = this.actionName.toUpperCase();
      let allowedActions = [];
      if (this.allowedActions) {
        allowedActions = this.allowedActions.map((elem) => {
          return elem.name.toUpperCase();
        });
      }
      if (applicationType === actionType) {
        return (
          allowedActions.indexOf(actionName) !== -1 && allowedActions.indexOf('OFFLINE_PAY') !== -1
        );
      } else {
        return !actionType && allowedActions.indexOf(actionName) !== -1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/utils/variables';
.pay--btn-section {
  @media #{$phones, $tablets} {
    flex-wrap: wrap;
    .btn {
      width: 100% !important;
    }
  }
}
</style>
